.owl-carousel.owl-theme.dept-carousel .owl-stage-outer{
  padding:20px 0;
}

.owl-carousel.owl-theme.dept-carousel .owl-nav button.owl-prev{
    position: absolute;
    left: 0;
    top: 30%;
    transform: translate(-50%, 0px);
}

.owl-carousel.owl-theme.dept-carousel .owl-nav button.owl-next{
    position: absolute;
    right: 0;
    top: 30%;
    transform: translate(50%, 0px);
}

.owl-carousel.owl-theme.dept-carousel{
   @apply mt-[5%] md:mt-[3%]
}