.pricing-Carousel{
    padding-top: 5%;
}

.owl-carousel.owl-theme.pricing-Carousel .owl-stage-outer{
    padding:10px 0px 40px;
}

.owl-carousel.owl-theme.pricing-Carousel :is(.owl-next,.owl-prev){
    position: absolute;
    top:50%;  
}

.owl-carousel.owl-theme.pricing-Carousel .owl-prev{
    left:0;
    transform: translateX(-50%);
}

.owl-carousel.owl-theme.pricing-Carousel .owl-next{
    right:0;
    transform: translateX(50%);
}

/* Common styling for pricing points */
.owl-carousel.owl-theme.pricing-Carousel{
    font-size: 16px;
    
    margin: 2px 0; /* Adjust this margin to decrease the vertical spacing */
  }
  

  
