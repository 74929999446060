@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Explore Link */
  .btn-primary {
    @apply bg-[#ef0000] text-[12px] inline-block px-[18px] py-[10px] tablet:text-lg sm:text-[1rem] lg:px-7 lg:py-3 text-[#fff] rounded-lg w-fit;
  }

  /* First Section Heading */
  .multiple-color {
    font-family: SegoeUI;
    @apply text-[#000] font-[800]  text-xl xl:text-4xl lg:text-3xl tablet:text-2xl;
  }

  .multiple-color.two {
    @apply text-[#fff];
  }
}
.multiple-color > span,
.multiple-color.two > span {
  @apply text-[#ef0000];
}

.paragraph {
  @apply text-[#6f6f6f] text-[12px]  font-[400] sm:text-[14px] md:text-[15px] lg:text-[1.1rem];
}
