img.student-image.student-image{
    width:130px;
    height: 130px;
    bottom:0;
    left:50%;
    transform: translate(-50%,50%);
    position: absolute;
}

img.quote.quote{
   width:20px;
   height:20px;
}