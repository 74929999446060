.hero {
    text-align: center;
    font-size: 32px;
    margin-top: 20px;
}

.grey {
    background-color: transparent;
    padding: 20px;
}

.content {
    max-width: 1400px;
    margin: 0 auto;
}

.blog-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.blog-post {
    width: calc(33.33% - 20px);
    margin-bottom: 20px;
    margin-right: 20px;
    padding: 10px;
    border: none;
    background-color: transparent;
    box-shadow: none;
    text-align: center;
    text-decoration: none;
    color: #000000;
    transition: transform 0.2s ease-in-out; /* Add transition for smooth hover effect */
}

.blog-post:hover {
    transform: scale(1.05); /* Scale up the post on hover */
}

.blog-post:last-child {
    margin-right: 0;
}

.blog-post img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.blog-post h2 {
    margin-top: 10px;
}


