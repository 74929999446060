.dept-sec-1{
    background: url("../../../assets/departmentLanding/department-sec-1-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 480px){
    .dept-sec-1{
        background: url("../../../assets/departmentLanding/dep-sec-1-mob.png");
        background-size: cover;
        background-repeat: no-repeat;
    }
}
