.footer-col{
  @apply md:w-[15%] w-[50%] flex flex-col gap-4 
}

.footer-col.footer-last{
    @apply xl:w-[15%] w-[100%]
}

.footer-heading{
    @apply pl-3 mb-2 font-[800] md:text-[1.25rem] text-[1rem] border-l-2 border-l-[#FD0000]
}

.footer-col>a{
   @apply no-underline md:text-[14px] text-[12px] font-[500]
}