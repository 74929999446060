.course-curve{
    position: absolute;
    border-radius: 60% 60%;
    border: solid 1px #FD0000;
    width: 100px;
    height: 60px;
    background: #fff;
    z-index:2;
    top: 60%;
}
.course-curve.left-curve{
    left: 0; 
    transform: translate(-86px,-50%);
}
.course-curve.right-curve{
    right: 0; 
    transform: translate(86px,-50%);
}
.course-card{
  box-shadow: inset 0px 0px 0px 1px #FD0000 ,0px 3px 15px 0px #00000029;
  border-radius:10px;
}

.course-card .course-img.course-img{
  width:80%;
}

.viewCourse{
  border-radius: 0 0 10px 10px;
  border-bottom: solid 1px #FD0000;
  width: calc(100% - 2px);
  margin: auto;
}


/* @media (min-width:640px){
    .course-card-wrapper.inactive{
        transform: scale(90%);
   }
   
   .course-card-wrapper.inactive{
       
   }
} */


