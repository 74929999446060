.collaborationHero{
    background-image: url('../../assets/collaboration/collaborationBG.png');
    width: 100%;
    display: flex; 
    background-position: center;
    background-size: cover;
    padding: 2%;
    margin: 2% 0%;
}
.collaborationCarousel{
    width: 40%;
}
.collaborationCarousel .owl-carousel .owl-item img  {
    width: 70%;
    display: block;
    margin: auto;
}
.collaborationdesi {
    text-align: center;
    width: 60%;
}
.collaborationdesi h2{
     font-size: 38px;
     color: #000000;
     font-weight: 600;
}
.collaborationdesi h1{
    font-size: 40px;
    color: #000000;
    font-weight: 700;
    padding: 1% 0;
}
.collaborationdesi h1 span{
    color:#EF0000;
}
.collaborationdesi p{
    font-size: 20px;
    color: #000000;
    line-height: 26px;
    padding: 2% 8%;
}
@media screen and (max-width:1200px) {
    .collaborationdesi h2{
        font-size: 28px;
   }
   .collaborationdesi h1{
    font-size: 30px;
   }
   .collaborationdesi p{
     line-height: 24px;
     font-size: 19px;
   }
}
@media screen and (max-width:1000px) {
    .collaborationCarousel .owl-carousel .owl-item img {
        width: 82%;
    }
}
@media screen and (max-width:720px) {
    .collaborationHero{
        flex-direction: column-reverse;
    }
    .collaborationCarousel,.collaborationdesi{
        width: 100%;
    }
    .collaborationCarousel .owl-carousel .owl-item img {
        width: 62%;
    }
    .collaborationCarousel {
        margin: 6% 0 0;
    }

}
@media screen and (max-width:600px) {
    .collaborationHero {
        padding: 5% 4%;
    }
}
@media screen and (max-width:500px) {
    .collaborationdesi h1 span {
        display: block;
    }
    .collaborationdesi p {
        padding: 2% 3% 7%;
    }
    .collaborationdesi h1 {
        line-height: 32px;
    }
    .collaborationdesi p {
        line-height: 22px;
        font-size: 17px;
    }
    .collaborationdesi h1 {
        font-size: 28px;
    }
    .collaborationdesi h2 {
        font-size: 26px;
    }
    .collaborationCarousel .owl-carousel .owl-item img {
        width: 69%;
    }
}
@media screen and (max-width:380px) {
    .collaborationdesi h1 {
        font-size: 22px;
        line-height: 23px;
        padding: 4% 0;
    }
    .collaborationdesi h2 {
        font-size: 20px;
    }
    .collaborationdesi p {
        line-height: 18px;
        font-size: 15px;
    }
    .collaborationCarousel .owl-carousel .owl-item img {
        width: 74%;
    }
}

