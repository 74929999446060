.pricingPlan[data-type="Self Paced"] .pricing-link{
    background:#BFB5F5
}
.pricingPlan[data-type="Self Paced"] .pricingtype{
    background:url("../../assets/innerPage/beginner.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 25px;
    
} 



.pricingPlan[data-type="Blended"] .pricing-link{
    background:#93e4e0
}
.pricingPlan[data-type="Blended"] .pricingtype{ 
    background:url("../../assets/innerPage/Blanded.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 25px;
} 



.pricingPlan[data-type="Mentor Led"] .pricing-link{
    background:#96D890
}
.pricingPlan[data-type="Mentor Led"] .pricingtype{
    background:url("../../assets/innerPage/intermediate.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 25px;
} 

  

.pricingPlan[data-type=Advanced] .pricing-link{
    background:#F5B3B3;
}
.pricingPlan[data-type=Advanced] .pricingtype{ 
    background:url("../../assets/innerPage/advanced.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 25px;
} 


