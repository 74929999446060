.section1Main{
    display: flex;
    width: 93%;
    margin: auto;
    background-image: linear-gradient(#F8A5A5,#FFFFFF);
    border-radius: 12px;
    padding: 6% 0 0 6%;
    position: relative;
    z-index: 0;
}
.section1Content,.section1Image{
    width:50%
}

.section1Content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
/* .section1Image{
    position: relative;
} */
.innerSec1lottie{
    position: absolute;
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
}
.section1Image>img{
    width: 75%;
    margin: 0 auto;
    z-index: 1;
}
.section1Content>p {
    font-size: 20px;
    line-height: 24px;
    margin: 4% 0;
}
/* ----mobile-version---------- */
@media screen and (max-width:610px) {
    .section1Main {
        width: 100%;
        border-radius: 0px;
        flex-direction: column;
        padding: 6% 12% 0;
    }
    .section1Content,.section1Image{
        width:100%
    }
    .section1Image>img {
        width: 75%;
        margin: 17% auto 0;
    }
    .section1Content>p {
        font-size: 18px;
    }
}
@media screen and (max-width:450px) {
    .section1Content>p {
        font-size: 15px;
    }
}