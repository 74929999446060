.updatedInputLabel {
  display: block;
}

.updatedContactMain {
  display: flex;
  position: relative;
  background-color: #ffffff;
  z-index: 2;
  padding: 4% 0;
}

.updatedContactUsAnimation {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.updatedContactDetails,
.updatedContactForm {
  width: 50%;
  position: relative;
}

.updatedContactDetails > img:nth-of-type(1) {
  width: 21%;
  position: absolute;
  top: 8%;
  z-index: -1;
  left: 14%;
}

.updatedContactUs {
  font-size: 48px;
  font-weight: 700;
  margin-top: -90px;
  color: #000000;
}

.updatedContactDetails > p {
  font-size: 20px;
  font-weight: 400;
  color: #404040;
}

.updatedEachContact {
  display: flex;
  align-items: center;
  gap: 1%;
  margin: 0 0 2%;
}

.updatedEachContact > img {
  width: 4%;
}

.updatedContactForm {
  padding: 0 0 0 8%;
  position: relative;
}

.updatedContactForm > img {
  position: absolute;
  bottom: 0;
  width: 20%;
  right: 0;
  z-index: -1;
}

.updatedContactSub1 {
  margin: 3% 0 0;
  margin-top: 50px;
}

.updatedFollowUs {
  display: flex;
  align-items: center;
  gap: 1%;
  margin: 25% 0 0;
  font-size: 20px;
  margin-top: 250px;
  margin-left: 10px;
}

.updatedFollowUs > p {
  text-size-adjust: 20px;
}

.updatedFollowUs > a {
  width: 4%;
}

.updatedContactDetails {
  padding: 7% 0 0 11%;
}

.updatedInputLabel {
  font-size: 20px;
  font-weight: 400;
  margin: 4% 0 0;
}

.updatedContactUsInput {
  box-shadow: 0px 3px 10px #56565629;
  border-radius: 7px;
  padding: 2% 2%;
  width: 88%;
  font-size: 18px;
}

.updatedNameInput > input {
  width: 40%;
  margin: 0 7% 0 0;
}

.updatedContactForm button {
  display: block;
  width: 50%;
  margin: 5% 0 0 20%;
}

.updatedContactForm .updatedTextarea {
  max-height: 121px;
  min-height: 121px;
  height: 121px;
}

.updatedContactnameMob {
  display: none;
}

.updatedBtnPrimary {
  font-size: 20px;
  color: #ffffff;
  background-color: rgb(189, 36, 36);
  border-radius: 20px; /* Adjust the border-radius as needed */
  padding: 2% 4%; /* Adjust padding to increase background height */
}

/* ===========mobile-version======================= */

@media screen and (max-width: 1000px) {
  .updatedContactDetails {
      padding: 10% 0 0 8%;
  }

  .updatedContactUs {
      font-size: 45px;
  }

  .updatedContactUsInput {
      font-size: 17px;
  }

  .updatedInputLabel {
      font-size: 19px;
  }

  .updatedContactForm {
      padding: 0 0 0 5%;
  }

  .updatedEachContact > img {
      width: 6%;
  }

  .updatedEachContact {
      gap: 3%;
  }

  .updatedFollowUs > a {
      width: 6%;
  }
}

@media screen and (max-width: 721px) {
  .updatedContactDetails {
      display: none;
  }

  .updatedContactForm {
      width: 90%;
      margin: 0 0 0 7%;
      padding: 0;
  }

  .updatedContactnameMob {
      display: block;
      text-align: center;
  }

  .updatedNameInput > input {
      width: 43%;
  }

  .updatedContactUsInput {
      width: 94%;
  }
}

@media screen and (max-width: 540px) {
  .updatedContactUs {
      font-size: 39px;
  }

  .updatedContactUsInput {
      font-size: 16px;
  }

  .updatedNameInput > input {
      width: 94%;
  }

  .updatedNameInput > input:nth-of-type(1) {
      margin-bottom: 4%;
  }

  .updatedContactForm {
      width: 90%;
      margin: 0 0 0 8%;
  }

  .updatedContactUsInput {
      padding: 3% 3%;
      font-weight: 500;
  }

  .updatedInputLabel {
      margin: 6% 0 0;
  }

  .updatedContactUsAnimation {
      height: 70%;
      width: 50%;
      transform: rotate(158deg);
  }
}

@media screen and (max-width: 430px) {
  .updatedContactUs {
      font-size: 30px;
  }

  .updatedContactMain {
      padding: 7% 0;
  }

  .updatedInputLabel {
      font-size: 17px;
  }

  .updatedContactUsInput {
      font-size: 15px;
  }
}
