
.il-section-1{
    background: url("../../assets/internshipLanding/sec-1-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
}
.circle1 , .circle2{
    transition: all 0.3s;
}
.sec-1-img-collage:hover .circle1 {
      transform:translate(20%,-20%);
      
}

.sec-1-img-collage:hover .circle2 {
    transform:translate(-20%,20%);
    
}