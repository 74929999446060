.grey .content {
    background-color: #F2F4FA;
    color: black;
    margin: 0% 5% 5% 5%;
    padding: 1% 4% 4% 4%;
}

.grey .content p {
    margin-top: 3%;
    font-size: 19px;
    /* font-weight: 500; */
    color: rgb(0, 0, 0);
}


.grey  h5 {
    /* margin-top: 3%; */
    font-size: 19px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    
}

.hero {
    padding: 5%;
    background-size: cover;
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    /* text-decoration: underline;
    text-decoration-color: red; */
    /* font-family:Arial, Helvetica, sans-serif; */
}
.hero1 {
    /* padding: 5%; */
    background-size: cover;
    /* text-align: center; */
    font-size: 35px;
    font-weight: 600;
    /* text-decoration: underline;
    text-decoration-color: red; */
    /* font-family:Arial, Helvetica, sans-serif; */
}


.heroTwo{
    background-size: cover;
    text-align: left;
    font-size: 30px;
    font-weight: 600;
    
}

.grey .content h2, .grey .content h3, .grey .content h1 {
    /* color: #324FAD; */
    text-transform: capitalize;
    margin-top: 3%;
    color: red;
}
