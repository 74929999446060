
  
  .dep-carousel-card::before{
    content:'';
    height:0%;
    width:0%;
    position:absolute;
    border-bottom: solid 100px transparent; 
    border-right:solid 100px red;
    right:0;
    top:0;
    transform:translate(8px, -8px);
    z-index:-1
  }

  .dep-carousel-card-img.dep-carousel-card-img.dep-carousel-card-img{
    width:30%;
    border-bottom-left-radius: inherit;
  }