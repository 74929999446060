.pagination-container {
    display: flex;
    list-style-type: none;
    justify-content: center;
    }

.pagination-item{
        padding: 0 12px;
        height: 32px;
        text-align: center;
        margin: auto 4px;
        color: #000;
        font-weight: 600;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        letter-spacing: 0.01071em;
        border-radius: 50%; 
        line-height: 1.43;
        font-size: 16px;  
        min-width: 32px;
        background-color:transparent;
}


.pagination-item:nth-child(1) ,.pagination-item:last-child{
    border: none;
}
.dots:hover {
    background-color: transparent;
    cursor: default;
    
}

.pagination-item:hover {
    background-color: #FFE7E7;
    cursor: pointer;
    color: #fd0000;
}

.pagination-container  .selected {
    background-color: #FFE7E7;
    color: #fd0000;
}

.arrowLeft ,.arrowRight{
    color: #000;
    font-weight: 600;
    font-size: 20px;
}

.disabled .arrowLeft ,.disabled .arrowRight{
    color: #A6A6A6;
}

.pagination-bar{
   align-items: center;
    gap: 20px;
    justify-content: center;
    display: flex;
   @apply mob:p-[4%] md:p-[2%] p-[5%]
}

