.nav-link-container{
  display:flex;
  align-items:center;
}

.nav-backdrop{
  display:none;
}

@media (min-width:992px){
  .nav-link-container{
    gap: 0px 56px;
  }
}


@media (min-width:768px){
  .nav-link-container{
    gap: 0px 40px;
  }
}

@media (max-width:767px){
  .nav-link-backdrop-wrapper{
    position: fixed;
    right:-100%;
    transition: all 0.3s;
    display: flex;
    width: 100%;
    height:100vh;
    overflow:hidden;
    BACKGROUND: #0000008c;
    TOP: 0;
    Z-INDEX: 2222222;
  }

  .nav-backdrop{
    display:block;
    height: inherit;
    width:20%;
  }

  .nav-link-container{
    background-color: #fff;
    width:80%;
    height:inherit;
    flex-direction: column;
    padding: 20px;
    border-radius: 30px 0px 0px 0px;
    gap: 40PX 0PX;
  }
  .nav-link.two{
    align-self: flex-start;
    margin-left: 40px;
    position: relative;
  }
  .nav-link.two::before{
    content: '';
    position: absolute;
    left:-20px;
    width:10px;
    height:2px;
    top:50%;
   background-color: #FD0000;
  }

  .side-nav-bottom{
    left: 20%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    align-items: flex-start;
    bottom: 0;
    padding: 20px 40px 20px;
    gap: 10px 0 ;
  }

}

.nav-link{
  @apply font-bold text-sm md:text-[15px] text-center no-underline
}

.nav-link{
  @apply font-bold text-sm text-center no-underline
}




.nav-contact-link{
  @apply flex items-center no-underline text-sm gap-4 
}