
.owl-carousel.owl-theme.home-sec-2-carousel .owl-item img{
    width:40px;
    height:40px;
}

.owl-carousel.owl-theme.sec-4-home-carousel{
     @apply md:hidden
 }

 .owl-carousel.owl-theme.course-carousel{
    @apply md:w-[90%] sm:w-[80%] w-[75%] m-auto 
 }


 .owl-carousel.owl-theme.course-carousel .owl-stage-outer{
    @apply pt-10
 }
 
 /* .owl-carousel.owl-theme.course-carousel .ow-item.active:nth-of-type(2n+1) .course-card{
    @apply sm:
 } */
 

 .owl-carousel.owl-theme.student-carousel{
   @apply translate-y-10
 }

 .owl-carousel.owl-theme.student-carousel .owl-stage-outer{
   @apply pb-14
 }