.car-input-label {
  display: block;
}

.car-contact-us {
  font-size: 45px;
  margin-top: -30px;
  font-weight: 700;
}

.car-contactMain {
  display: flex;
  position: relative;
  background-color: #ffffff;
  z-index: 2;
  padding: 4% 0;
}

.car-bullet-list {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 20px;
}

.car-small-image {
  margin-left: -40px;
  margin-top: -1px;
  width: 300px;
  height: auto;
}

.car-small-image1 {
  margin-left: 180px;
  margin-top: -380px;
  width: 500px;
  height: auto;
}

.car-contactUsAnimation {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.car-contact-details {
  width: 50%;
  position: relative;
  margin-top: -30px;
}

.car-contact-details,
.car-contactForm {
  width: 50%;
  position: relative;
}

.car-contact-details>img:nth-of-type(1) {
  width: 21%;
  position: absolute;
  top: 8%;
  z-index: -1;
  left: 14%;
}

.car-bullet-list {
  font-size: 20px;
  font-weight: 400;
  color: #404040;
}

.car-each-conatct {
  display: flex;
  align-items: center;
  gap: 1%;
  margin: 0 0 2%;
}

.car-each-conatct>img {
  width: 4%;
}

.car-contactForm {
  padding: 0 0 0 8%;
  position: relative;
  margin-top: 20px;
}

.car-contactForm>img {
  position: absolute;
  bottom: 0;
  width: 20%;
  right: 0;
  z-index: -1;
}

.car-contact-sub1 {
  margin: 3% 0 0;
  margin-top: 50px;
}

.car-follow-us {
  display: flex;
  align-items: center;
  gap: 1%;
  margin: 25% 0 0;
  font-size: 20px;
  margin-top: 250px;
  margin-left: 10px;
}

.car-follow-us>p {
  text-size-adjust: 20px;
}

.car-follow-us>a {
  width: 4%;
}

.car-contact-details {
  padding: 7% 0 0 11%;
}

.car-input-label {
  font-size: 20px;
  font-weight: 400;
  margin: 4% 0 0;
}

.car-contactUs-input {
  box-shadow: 0px 3px 10px #56565629;
  border-radius: 7px;
  padding: 2% 2%;
  width: 88%;
  font-size: 18px;
}

.car-name-input>input {
  width: 40%;
  margin: 0 7% 0 0;
}

.car-contactForm button {
  display: block;
  width: 50%;
  margin: 5% 0 0 20%;
}

.car-contactForm .textarea {
  max-height: 121px;
  min-height: 121px;
  height: 121px;
}

.car-contactname-mob {
  display: none;
}

.car-btn-primary {
  font-size: 20px;
  color: #ffffff;
  background-color: rgb(189, 36, 36);
  border-radius: 20px; /* Adjust the border-radius as needed */
  padding: 2% 4%; 
}

/* ===========mobile-version======================= */

@media screen and (max-width: 1000px) {
  .car-contact-details {
    padding: 10% 0 0 8%;
  }
  .car-contact-us {
    font-size: 45px;
  }
  .car-contactUs-input {
    font-size: 17px;
  }
  .car-small-image {
    margin-left: -20px;
    margin-top: 9px;
    width: 150px;
    height: auto;
  }

  .car-small-image1 {
    margin-left: 100px;
    margin-top: -190px;
    width: 250px;
    height: auto;
  }

  .car-contact-details {
    width: 50%;
    position: relative;
    margin-top: -30px;
  }
  .car-input-label {
    font-size: 19px;
  }
  .car-contactForm {
    padding: 0 0 0 5%;
  }
  .car-each-conatct>img {
    width: 6%;
  }
  .car-each-conatct {
    gap: 3%;
  }
  .car-follow-us>a {
    width: 6%;
  }
}

@media screen and (max-width: 721px) {
  .car-contactMain {
    flex-direction: column; /* Stack elements vertically on mobile */
  }

  .car-contact-details {
    display: block; /* Display content as a block on mobile */
    width: 100%; /* Full width for content on mobile */
    margin-top: 20px; /* Remove margin on top for content */
    padding: 0 8%; /* Add some padding to content for mobile */
  }

  .car-contactForm {
    width: 100%; /* Full width for the form on mobile */
    margin-top: 20px; /* Add margin for separation */
    padding: 0 8%; /* Add padding to the form for mobile */
  }

  .car-small-image {
    margin-left: -30px;
    margin-top: -3px;
    width: 200px;
    height: auto;
  }
  .car-small-image1 {
    margin-left: 140px;
    margin-top: -250px;
    width: 310px;
    height: auto;
  }

  .car-input-label {
    font-size: 19px; /* Adjust font size for form labels on mobile */
  }

  .car-contactUs-input {
    font-size: 17px; /* Adjust font size for form inputs on mobile */
  }

  .car-each-conatct > img {
    width: 6%; /* Adjust contact icons size on mobile */
  }

  .car-each-conatct {
    gap: 3%; /* Adjust spacing between contact details on mobile */
  }

  .car-follow-us > a {
    width: 6%; /* Adjust social media icons size on mobile */
  }
}

@media screen and (max-width: 540px) {
  .car-contact-us {
    font-size: 39px;
  }
  .car-contactUs-input {
    font-size: 16px;
  }
  .car-name-input>input {
    width: 94%;
  }
  .car-small-image {
    margin-left: -20px;
    margin-top: 1px;
    width: 150px;
    height: auto;
  }
  .car-small-image1 {
    margin-left: 80px;
    margin-top: -190px;
    width: 240px;
    height: auto;
  }
  .car-name-input>input:nth-of-type(1) {
    margin-bottom: 4%;
  }
  .car-contactForm {
    width: 90%;
    margin: 0 0 0 8%;
  }
  .car-contactUs-input {
    padding: 3% 3%;
    font-weight: 500;
  }
  .car-input-label {
    margin: 6% 0 0;
  }
  .car-contactUsAnimation {
    height: 70%;
    width: 50%;
    transform: rotate(158deg);
  }
}

@media screen and (max-width: 430px) {
  .car-contact-us {
    font-size: 40px;
    text-align: center;
  }
  .car-contactMain {
    padding: 7% 0;
  }
  .car-input-label {
    font-size: 17px;
  }
  .car-contactUs-input {
    font-size: 15px;
  }
  .car-small-image {
    display: none;
  }
  .car-small-image1 {
    display: none;
  }
}
