.m-carousel-heading{
    margin: 0 auto 0 0
}
.owl-carousel.owl-theme.mentorCarousel .owl-stage-outer{
  @apply p-3
}
.owl-carousel.owl-theme.mentorCarousel{
    @apply md:p-0 md:w-[100%] w-[75%] m-auto static
}

.owl-carousel.owl-theme.mentorCarousel .owl-nav{
    left: -75%;
    top: 90%;
    @apply md:absolute;
   
}

.owl-carousel.owl-theme.mentorCarousel .owl-nav>button{
    @apply md:static absolute top-[50%] -translate-y-[50%]
}

.owl-carousel.owl-theme.mentorCarousel .owl-nav>button.owl-prev{
    @apply left-0
}
.owl-carousel.owl-theme.mentorCarousel .owl-nav>button.owl-next{
    @apply right-0
}