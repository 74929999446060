.popUpFormEachDetail{
    /* display: block; */
    font-size: 23px;
}
.popUpform>img{
    width: 20%;
    margin: auto;
}
.popUpMain{
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top:0;
    left:0;
}
.popupFromDetails input{
    box-shadow: #56565629 0px 3px 10px;
    font-size: 20px;
    border-radius: 10px;
    padding: 1% 2%;
    width: 82%;
    display: block;
    margin: 0% 0 3%;
}
.popupFromDetails {
    padding: 5% 0 0% 16%;
}
.popUpform{
    background-color: white;
    width: 50%;
    padding: 2% 0;
    border-radius: 10px;
    position: relative;
}
.popupcross{
    position: absolute;
    right: 2%;
    top: 2%;
    font-size: 20px;
    cursor: pointer;
}
.formbtn{
    display: block;
    margin: 0 0 0 33%;
}
/* ------------mobile-version----------------------- */

@media screen and (max-width:900px) {
    .popUpform {
        width: 66%;
    }
}
@media screen and (max-width:720px) {
    .popUpFormEachDetail {
        font-size: 20px;
    }
    .popupFromDetails input {
        font-size: 18px;
        margin: 0% 0 5%;
        width: 86%;
    }
    .popUpform>img {
        width: 33%;
    }
    .popupFromDetails {
        padding: 5% 0 0% 13%;
    }
}
@media screen and (max-width:515px) {
    .popUpform {
        width: 73%;
        padding: 4% 0;
    }
    .popupcross {
        font-size: 18px;
    }
}
@media screen and (max-width:430px) {
    .popUpform>img {
        width: 36%;
    }
    .popUpFormEachDetail {
        font-size: 18px;
    }
    .popupFromDetails input {
        border-radius: 6px;
    }
}