@import url("https://fonts.cdnfonts.com/css/proxima-nova-2");
@import url("https://fonts.cdnfonts.com/css/segoe-ui-4");
@font-face {
  font-family: SegoeUI;
src:url("./assets/fontFamilies/FontsFree-Net-Segoe-UI-Bold.ttf")
}

* {
@apply p-0 m-0 box-border scroll-smooth;
  font-family: "Proxima Nova";

}

/* scrollbar width */
::-webkit-scrollbar {
  width: 2px;
  height: 1px;;
}

/* scrollbar Track 
::-webkit-scrollbar-track {
   background: ''
 } */

/*scroll bar Handle */
::-webkit-scrollbar-thumb {
  background:#fd0000;
}

.Segoe-UI {
  font-family: SegoeUI;
}




.hide-text{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

