.accordian-head{
    @apply flex  items-center gap-[10px] cursor-pointer
}


.accordian-head img{
    width: 16px;
    height: 16px
}

.accordian-body{
    max-height: 0;
    opacity: 0;
    border-radius: 10px;
    overflow: hidden;
    padding: 0px 15px 0px 26px;
    transition: all 0.3s;
}


.accordian-body.active{
    opacity: 1
}

.accordian-main{
    background:#F3F3F3;
    box-shadow:0px 3px 10px #0000005E;
    border-radius: 10px;
    @apply p-[15px] md:p-[18px_15px]
}

.accordian-main.active{
    background:#FFFFFF;
}

.accordian-main .minus{
 display: none;
}
 
.accordian-main.active .minus{
    display: block;
}

.accordian-main .plus{
    display: block;
   }
    
   .accordian-main.active .plus{
       display: none;
   }