.point-wrapper{
    border:solid 2px;
    border-radius: 50%;
    border-left:none;
    border-top:none;
    border-bottom:none;
    position: relative;
    z-index: 10;
    background: #fff;
}


.curve-border{
    border: solid 2px;
    border-left: none;
    position: relative;
    border-radius: 0% 14% 14% 0% / 96% 100% 100% 100%;
}

.curve-border::before{
    content: '';
    position: absolute;
    background: inherit;
    width: 100%;
    transform: scaleX(0);
    height:100%;
    left:0;
    transition: all 0.3s;
    z-index: -1;
    border-radius: inherit;
    border:inherit;
    transform-origin: left ;
}

.curve-border:hover::before{
    transform: scaleX(1);
}
.curve-border.one:hover::before{
    background: #BDAFFF;
}
.curve-border.two:hover::before{
    background: #FFFAAC;
}
.curve-border.three:hover::before{
    background: #C7FFAA;
}
.curve-border.four:hover::before{
    background: #FFDCD6;
}