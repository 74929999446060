.owl-carousel .owl-item img{
    width:auto;
}

.owl-theme .owl-dots .owl-dot.active span{
    width:40px;
}
/* ------------------ */
.owl-carousel .owl-stage {
    margin: auto;
}
/* ----------------- */
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background:#EF0000
}


/* Nav buttons styling */

.owl-nav>button>span{
   display:none;
}

.owl-carousel .owl-nav button.owl-prev.owl-prev{
    background: url("../../assets/icons/nav-left.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.owl-carousel .owl-nav button.owl-next.owl-next{
    background: url("../../assets/icons/nav-right.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.owl-carousel .owl-nav button.owl-prev.disabled{
    background: url("../../assets/icons/nav-left-disabled.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.owl-carousel .owl-nav button.owl-next.disabled{
    background: url("../../assets/icons/nav-right-disabled.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.owl-carousel .owl-nav button{
    opacity: 1 !important;
    @apply md:w-[50px] md:h-[50px] w-[30px] h-[30px]
}

