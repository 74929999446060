.contactMain1 {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  background-color: #ffffff;
  padding: 5% 0;
  margin-left: 80px;
}

.contact-details1 {
  width: 50%;
}

.contact-details1 img {
  width: 100%; /* Adjusted to take the full width of the container */
  max-width: 200px; /* Set a maximum width for the image */
  margin-top: 8%; /* Adjusted for better positioning */
  position: relative; /* Adjusted for better positioning */
  left: 50%; /* Center the image horizontally */
  transform: translateX(-50%); /* Center the image horizontally */
}

.contact-us1 {
  font-size: 100px;
  margin-top: 0;
}

.contactForm {
  width: 50%;
}

/* Media queries for responsiveness */
@media screen and (max-width: 1000px) {
  .contact-details1 {
    width: 100%; /* Make the contact details take full width on smaller screens */
  }
}

@media screen and (max-width: 721px) {
  .contact-us1 {
    font-size: 60px; /* Adjust font size for smaller screens */
  }
}

@media screen and (max-width: 540px) {
  .contact-us1 {
    font-size: 40px; /* Adjust font size for even smaller screens */
  }
}

@media screen and (max-width: 430px) {
  .contact-us1 {
    font-size: 32px;
    margin-left: -4px;
    margin-top: 20px; 
  }
  .contact-details1 {
    width: 100%;
    margin-left: -30px; /* Remove left margin for better alignment */
    /* margin-top: -40px; */
    margin-bottom: 105px;
  }
}
